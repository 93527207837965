import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { SESSION_TIMEOUT } from "../../helper/constant";

const useInactivityTimer = (onExpire, timeout = SESSION_TIMEOUT) => {
  let timer;

  const resetTimer = () => {
    clearTimeout(timer);
    timer = setTimeout(onExpire, timeout);
  };

  const handleActivity = () => {
    resetTimer();
  };

  useEffect(() => {
    resetTimer();
    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);

    return () => {
      clearTimeout(timer);
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
    };
  }, [timeout, onExpire]);

  return resetTimer;
};

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const isActive = (path) => {
    if (path === "/") {
      return location.pathname === "/";
    } else {
      return location.pathname.startsWith(path);
    }
  };

  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will be logged out!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, logout!",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("token");
        navigate("/");
        toast.success("You have been logged out!");
      }
    });
  };

  useInactivityTimer(() => {
    Swal.fire({
      position: "top-end",
      title: "Session Timeout",
      text: "You have been logged out due to inactivity.",
      icon: "warning",
      showConfirmButton: false,
      timer: 1500,
    }).then(() => {
      localStorage.removeItem("token");
      navigate("/");
      toast.error("Session expired!");
    });
  }, SESSION_TIMEOUT); // 1 minutes timeout

  return (
    <>
      <div className="sidebar-content">
        <div className="sidebar-logo">
          <Link to="/" target="_blank">
            <img src="images/sidebar-logo.png" alt="sidebar" />
          </Link>
        </div>
        <div className="sidebar-menu">
          <ul>
            {/* <li className="active"> */}
            <li
              className={`nav-link ${
                isActive("/dashboard") ? "auth-active" : ""
              }`}
            >
              <Link to="/dashboard">
                <i className="ti ti-layout-dashboard" />
                <span>Dashboard</span>
              </Link>
            </li>
            <li
              className={`nav-link ${isActive("/apps") ? "auth-active" : ""}`}
            >
              <Link to="/apps">
                <i className="ti ti-apps" />
                <span>Apps</span>
              </Link>
            </li>
            <li
              className={`nav-link ${
                isActive("/transactions") ? "auth-active" : ""
              }`}
            >
              <Link to="/transactions">
                <i className="ti ti-currency-dollar" />
                <span>Transactions</span>
              </Link>
            </li>

            <li
              className={`nav-link ${
                isActive("/withdraw-invoice") ? "auth-active" : ""
              }`}
            >
              <Link to="/withdraw-invoice">
                <i className="ti ti-step-into" />
                <span>Withdraw Invoices</span>
              </Link>
            </li>

            <li
              className={`nav-link ${
                isActive("/customer-support") ? "auth-active" : ""
              }`}
            >
              <Link to="/customer-support">
                <i className="ti ti-phone-call" />
                <span>Customer Support</span>
              </Link>
            </li>
            <li
              className={`nav-link ${
                isActive("/my-profile") ? "auth-active" : ""
              }`}
            >
              <Link to="/my-profile">
                <i className="ti ti-user" />
                <span>My Profile</span>
              </Link>
            </li>

            <li
              className={`nav-link ${
                isActive("/settings") ? "auth-active" : ""
              }`}
            >
              <Link to="/settings">
                <i className="ti ti-settings" />
                <span>My Settings</span>
              </Link>
            </li>

            <li
              className={`nav-link ${
                isActive("/change-password") ? "auth-active" : ""
              }`}
            >
              <Link to="/change-password">
                <i className="ti ti-lock" />
                <span>Change Password</span>
              </Link>
            </li>
            <li onClick={handleLogout}>
              <a href="javascript:;">
                <i className="ti ti-logout-2" />
                <span>Logout</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
