import React, { useState, useEffect } from "react";
import DashboardHeader from "../../components/auth/DashboardHeader";
import Sidebar from "../../components/auth/Sidebar";
import { Link, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { QRCodeCanvas } from "qrcode.react";
import { toast } from "react-toastify";

const DepositReceive = () => {
  //apps detail
  const { appId } = useParams();
  const [appName, setAppName] = useState("");
  const [walletInfo, setWalletInfo] = useState("");
  // const [evmMnemonicAddress, setEvmMnemonicAddress] = useState("");
  // const [tronMnemonicAddress, setTronMnemonicAddress] = useState("");
  const [appDate, setAppDate] = useState("");

  const location = useLocation();
  console.log("location", location);

  const { tokenId, tokenNetwork } = location.state || {};

  //token detail
  const [tokenInfo, setTokenInfo] = useState(null);

  console.log("tokenNetwork", tokenNetwork, walletInfo);

  useEffect(() => {
    const fetchAppDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/apps/getById`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            params: {
              appId: appId,
            },
          }
        );
        console.log("responseresponse", response);
        if (response?.data) {
          setAppName(response?.data?.data?.name);

          if (tokenNetwork === "BITCOIN") {
            setWalletInfo(response?.data?.data?.BtcWalletMnemonic?.address);
          } else if (tokenNetwork === "TRON") {
            setWalletInfo(response?.data?.data?.TronWalletMnemonic?.address);
          } else {
            setWalletInfo(response?.data?.data?.EVMWalletMnemonic?.address);
          }

          setAppDate(response?.data?.data?.createdAt);
        }
      } catch (error) {
        console.error("Error fetching Mnemonic details:", error);
      }
    };
    fetchAppDetails();
  }, [appId]);

  console.log("tokenId", tokenId);

  useEffect(() => {
    const fetchTokenDetail = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/token/getById`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            params: {
              tokenId: tokenId,
            },
          }
        );
        // console.log("responseresponse", response);
        if (response?.data) {
          setTokenInfo({
            network: response?.data?.data?.network,
            symbol: response?.data?.data?.symbol,
          });
        }
      } catch (error) {
        console.error("Error fetching Mnemonic details:", error);
      }
    };
    fetchTokenDetail();
  }, [tokenId]);

  // Function to copy the address to the clipboard
  const copyToClipboard = (walletAddress) => {
    if (walletAddress) {
      navigator.clipboard.writeText(walletAddress);
      toast.success("Copied to clipboard!");
    }
  };

  return (
    <>
      <DashboardHeader />
      <div className="site-bg">
        <div className="dashborad-content-bg">
          <aside className="sidebar">
            <Sidebar />
          </aside>
          <div className="dashborad-warper">
            <div className="transactions-list">
              <div className="dashborad-head">
                <div className="breadcrumb-menu">
                  <ul>
                    <li className="active">
                      <Link to="/apps">Apps &rarr; </Link>
                    </li>
                    <li className="active">
                      <Link to={`/apps-detail/${appId}`}>{appName}</Link>
                    </li>
                    <li>
                      <a>&rarr; Transfer History</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="white-bg my-apps-content padd20">
                <div className="table-box">
                  <table className="table">
                    <tbody>
                      <tr>
                        <th>Deposit Address- {tokenInfo?.symbol}</th>
                        <th>QR</th>
                        {/* <th>Date Added</th> */}
                      </tr>
                      <tr>
                        <td>
                          <div className="edit-label-box">
                            {/* <p>Label 1</p> */}
                            <p>
                              Deposit Address for{" "}
                              <span className="bold">{tokenInfo?.symbol}</span>{" "}
                              on{" "}
                              <span className="bold">
                                ({tokenInfo?.network})
                              </span>{" "}
                              network
                            </p>
                            <br />
                            <p>
                              <span>{walletInfo}</span>
                              <button
                                onClick={() => copyToClipboard(walletInfo)}
                                style={{
                                  marginLeft: "10px",
                                  background: "none",
                                  border: "none",
                                  cursor: "pointer",
                                }}
                                title="Copy to clipboard"
                              >
                                <i className="ti ti-copy" />
                              </button>
                            </p>
                            <br />
                          </div>
                        </td>
                        <td>
                          <div className="QR-scan">
                            {walletInfo ? (
                              <QRCodeCanvas
                                style={{ width: "200px", height: "200px" }}
                                value={walletInfo}
                              />
                            ) : (
                              <p>Loading...</p>
                            )}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* <div className="add-deposit-address">
                  <a  className="btn">
                    Add New Deposit Address
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Add label
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label>Label Name</label>
                <div className="form-group-inner">
                  <div className="fild-icon">
                    <i className="ti ti-keyframe" />
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Label Name"
                  />
                </div>
              </div>
              <div className="form-submit-btn">
                <button className="btn">Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DepositReceive;
