import React from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

const DashboardHeader = ({ appId }) => {
  const location = useLocation();

  const getTitle = (appId) => {
    console.log("Rahul ------ ", appId);

    switch (location.pathname) {
      case "/dashboard":
        return "Dashboard";
      case "/apps":
        return "Apps";
      case "/transactions":
        return "Payment Links";
      case "/customer-support":
        return "Customer Support";
      case "/my-profile":
        return "My Profile";
      case "/change-password":
        return "Change Password";
      case "/apps-transfer-history":
        return "Apps";
      case "/apps-payment-links":
        return "Apps";
      case "/apps-wallet-transactions":
        return "Apps";
      case "/apps-detail":
        return "Apps";
      case "/apps-send-withdraw":
        return "Apps";
      case "/apps-deposit-receive":
        return "Apps";
      case `/apps-detail/${appId}`:
        return "Apps";
      case "/settings":
        return "My Settings";
      case `/apps-payment-links/${appId}`:
        return "Payment Links";
      case `/apps-wallet-transactions/${appId}`:
        return "Wallet Transaction";
      case "/withdraw-invoice":
        return "Withdraw Invoice";
      default:
        return "Dashboard";
    }
  };
  return (
    <>
      <header className="dashboard-header">
        <div className="header-inner">
          <div className="dashboard-header-left">
            <button className="menu-toggal">
              <img src="images/menu-line.svg" alt="menu" />
            </button>
            <div className="logo">
              <a>
                <img src="images/logo.png" alt="logo" />
              </a>
            </div>
            <h2>{getTitle(appId)}</h2>
          </div>
          <div
            className="dashboard-header-right"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "1rem",
              cursor: "pointer",
              // color: "#666666",
            }}
          >
            <figure>
              <Link to="/notifications">
                <img
                  src="images/bell-icon-transparent-notification-free-png.webp"
                  alt="notification icon"
                />
              </Link>
            </figure>

            <figure>
              <Link to="/my-profile">
                <img src="images/user-profile.png" alt="user profile" />
              </Link>
            </figure>
          </div>
        </div>
      </header>
    </>
  );
};

export default DashboardHeader;
