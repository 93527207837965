import React, { useEffect, useState, useRef } from "react";
import DashboardHeader from "../../components/auth/DashboardHeader";
import Sidebar from "../../components/auth/Sidebar";
import { Link, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import {
  evmCryptoBalanceCheck,
  getERC20TransferTxFee,
  getNativeTransferTxFee,
  isSwapPair,
  NATIVE,
} from "../../helper/evmHelper";
import { ethers } from "ethers";
import { Loader } from "../../components/Loader";
import { ThreeDotLoader } from "../../components/ThreeDotLoader";
import {
  errorSwal,
  getTokenContractAddresses,
  successSwal,
} from "../../helper/helper";
import { useDispatch, useSelector } from "react-redux";
import { getValidateTronAddress } from "../../helper/tronHelper";
import { validate } from "bitcoin-address-validation";
import {
  BTC_CHAIN_ID,
  EVM_CHAIN_ID_LIST,
  TRON_CHAIN_ID,
} from "../../helper/constant";
import Swal from "sweetalert2";

const SendWithdraw = () => {
  const location = useLocation();
  const { appId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const { tokenId } = location.state || {};
  const [tokenInfo, setTokenInfo] = useState(null);
  const [note, setNote] = useState("");
  const [appInfo, setAppInfo] = useState("");
  const [merchantBalance, setMerchantBalance] = useState(0);
  const [merchantEvmNativeBalance, setMerchantEvmNativeBalance] = useState(0);
  const [receiverAddress, setReceiverAddress] = useState("");
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [minimumWithdraw, setMinimumWithdraw] = useState(0);
  const [transactionFee, setTransactionFee] = useState(0);
  const [miniLoader, setMiniLoader] = useState(false);
  const intervalRef = useRef(null);
  const [tokensList, setTokensList] = useState([]);
  const [tokenB, setTokenB] = useState("");
  const [isSwapPairFound, setIsSwapPairFound] = useState(false);
  const [swapConvertedAmount, setSwapConvertedAmount] = useState(0);

  let timeoutId = null;
  const getTokenAddresses = async () => {
    if (tokenInfo?.chainId) {
      const data = getTokenContractAddresses(tokenInfo?.chainId);
      setTokensList(data);
    }
  };

  const fetchAppDetails = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/apps/getById`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            appId: appId,
          },
        }
      );

      if (response?.data) {
        setAppInfo(response?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching Mnemonic details:", error);
    }
  };

  useEffect(() => {
    fetchAppDetails();
  }, [appId]);

  const fetchTokenDetail = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/token/getById`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            tokenId: tokenId,
          },
        }
      );
      if (response?.data) {
        setTokenInfo(response?.data?.data);
        setMinimumWithdraw(response?.data?.data?.minWithdraw);
      }
      return response?.data?.data?.minWithdraw;
    } catch (error) {
      console.error("Error fetching Mnemonic details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTokenDetail();
  }, [tokenId]);

  const getTransactionFee = async (tokenAddress) => {
    try {
      if (tokenInfo?.chainId === TRON_CHAIN_ID) {
        setTransactionFee(12);
      } else {
        if (
          receiverAddress.length === 42 &&
          tokenAddress[0] === "0" &&
          tokenAddress[1] === "x"
        ) {
          if (tokenAddress === NATIVE) {
            const tx = {
              to: receiverAddress,
              value:
                tokenInfo?.chainId === BTC_CHAIN_ID
                  ? (withdrawAmount * 10 ** 8).toFixed(8) // parse to satoshi
                  : tokenInfo?.chainId === TRON_CHAIN_ID
                  ? (withdrawAmount * 10 ** 6).toFixed(6) // parse to tron
                  : ethers.utils.parseEther(withdrawAmount.toString()),
            };
            const value = await getNativeTransferTxFee(tokenInfo?.chainId, tx);
            setTransactionFee(value.slice(0, 8));
          } else {
            const value = await getERC20TransferTxFee(
              tokenInfo?.chainId,
              tokenInfo?.address,
              receiverAddress,
              withdrawAmount,
              tokenInfo?.decimal
            );

            setTransactionFee(value.slice(0, 8));
          }
        } else {
          setTransactionFee(0);
        }
      }
    } catch (e) {
      console.error("tx fee getting error", e);
    }
  };

  const startTransactionFeeInterval = async () => {
    intervalRef.current = setInterval(() => {
      setMiniLoader(true);
      setTimeout(async () => {
        await getTransactionFee(tokenInfo?.address);
        setMiniLoader(false);
      }, 2000);
    }, 18000);
    getTransactionFee(tokenInfo?.address);
  };

  const clearTransactionFeeInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  useEffect(() => {
    if (receiverAddress !== "" && withdrawAmount !== "") {
      startTransactionFeeInterval();
    }
    return () => clearTransactionFeeInterval();
  }, [receiverAddress, withdrawAmount, tokenInfo?.address]);

  async function getCryptoBalance() {
    setIsLoading(true);
    console.log("-------------------------------- TTT : ", appInfo, tokenInfo);

    if (!(tokenInfo && appInfo)) {
      return 0;
    }

    const walletAddress =
      tokenInfo?.chainId === BTC_CHAIN_ID
        ? appInfo?.BtcWalletMnemonic.address
        : tokenInfo?.chainId === TRON_CHAIN_ID
        ? appInfo?.TronWalletMnemonic?.address
        : appInfo?.EVMWalletMnemonic?.address;

    // return 0;

    const balance = await evmCryptoBalanceCheck(
      tokenInfo?.chainId,
      tokenInfo?.address,
      walletAddress,
      tokenInfo?.decimal
    );

    const nativeBalance = await evmCryptoBalanceCheck(
      tokenInfo?.chainId,
      NATIVE,
      walletAddress,
      tokenInfo?.decimal
    );

    setMerchantEvmNativeBalance(nativeBalance);
    setMerchantBalance(balance);
    setIsLoading(false);
    return balance;
  }

  useEffect(() => {
    try {
      getCryptoBalance();
      getTokenAddresses();
    } catch (error) {
      console.log("Error : ", error);
    }
  }, [tokenInfo, appInfo]);

  console.log("tokenInfo : ", tokenInfo);

  // const submitTransaction = async function () {
  //   try {
  //     setIsLoading(true);
  //     if (minimumWithdraw > withdrawAmount) {
  //       errorSwal(
  //         "Invalid Inputs",
  //         `Minimum withdrawal amount is ${minimumWithdraw} ${tokenInfo?.symbol}`
  //       );
  //       return;
  //     }

  //     if (merchantEvmNativeBalance < 0.00001) {
  //       errorSwal(
  //         "Validation Failed",
  //         `Insufficient ${tokenInfo?.network} native balance for transaction`
  //       );
  //       return;
  //     }
  //     // Rahul

  //     if (tokenInfo?.chainId === TRON_CHAIN_ID) {
  //       const isValidTronAddress = await getValidateTronAddress(
  //         receiverAddress
  //       );
  //       if (!isValidTronAddress) {
  //         errorSwal("Validation Failed", `Invalid receiver wallet address`);
  //         return;
  //       }

  //       if (tokenInfo?.address !== NATIVE && merchantEvmNativeBalance < 12) {
  //         errorSwal("Native Balance should be equal to or greater than 12 TRX");
  //         return;
  //       }
  //     } else if (tokenInfo?.chainId === BTC_CHAIN_ID) {
  //       if (!validate(receiverAddress)) {
  //         errorSwal("Validation Failed", `Invalid receiver wallet address`);
  //         return;
  //       }
  //     } else if (EVM_CHAIN_ID_LIST.includes(tokenInfo?.chainId)) {
  //       if (!ethers.utils.isAddress(receiverAddress)) {
  //         errorSwal("Validation Failed", `Invalid receiver wallet address`);
  //         return;
  //       }
  //     } else {
  //       errorSwal(
  //         "Unsupported Chain",
  //         `Chain ID ${tokenInfo?.chainId} is not supported`
  //       );
  //       return;
  //     }

  //     const url = `${process.env.REACT_APP_API_URL}/merchant-app-tx/merchant-withdraw`;
  //     const data = {
  //       appsId: appId,
  //       tokenId: tokenId,
  //       amount: withdrawAmount,
  //       withdrawalAddress: receiverAddress,
  //       isWithTax: true,
  //       note: "Withdrawal from merchant panel",
  //       swapTokenAddress: isSwapPairFound ? tokenB : null,
  //     };
  //     console.log("URL: ", url, data);

  //     Swal.fire({
  //       title: "Do you want to withdraw fund?",
  //       text: "You won't be able to revert this transaction!",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonColor: "#3085d6",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: "Withdraw",
  //     }).then(async (result) => {
  //       if (result.isConfirmed) {
  //         setIsLoading(true);
  //         const response = await axios.post(url, data, {
  //           headers: {
  //             Authorization: `Bearer ${localStorage.getItem("token")}`,
  //             "Content-Type": "application/json",
  //           },
  //         });
  //         console.log("Response: ", response);

  //         if (response?.data?.merchantReceipt?.status) {
  //           Swal.fire({
  //             title: "Transaction Successful!",
  //             text: "Transaction initiated successfully. Please wait for the confirmation from the network.",
  //             icon: "success",
  //           });
  //         } else {
  //           errorSwal("Transaction Failed", "Failed to initiate transaction.");
  //         }
  //       }
  //     });
  //   } catch (error) {
  //     console.log("Error in transaction submission : ", error);
  //     errorSwal("Transaction Failed", error.response?.data?.message);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const submitTransaction = async function () {
    try {
      setIsLoading(true);

      // Check if withdrawal amount is less than the minimum allowed
      if (withdrawAmount < minimumWithdraw) {
        errorSwal(
          "Invalid Inputs",
          `Minimum withdrawal amount is ${minimumWithdraw} ${tokenInfo?.symbol}`
        );
        return;
      }

      // Check if the native balance is sufficient
      if (merchantEvmNativeBalance < 0.00001) {
        errorSwal(
          "Validation Failed",
          `Insufficient ${tokenInfo?.network} native balance for the transaction`
        );
        return;
      }

      // Chain-specific validations
      if (tokenInfo?.chainId === TRON_CHAIN_ID) {
        const isValidTronAddress = await getValidateTronAddress(
          receiverAddress
        );
        if (!isValidTronAddress) {
          errorSwal("Validation Failed", `Invalid receiver wallet address`);
          return;
        }

        // TRX-specific balance validation
        if (tokenInfo?.address !== NATIVE && merchantEvmNativeBalance < 12) {
          errorSwal(
            "Validation Failed",
            `Native balance must be at least 12 TRX for transactions on this chain`
          );
          return;
        }
      } else if (tokenInfo?.chainId === BTC_CHAIN_ID) {
        if (!validate(receiverAddress)) {
          errorSwal("Validation Failed", `Invalid receiver wallet address`);
          return;
        }
      } else if (EVM_CHAIN_ID_LIST.includes(tokenInfo?.chainId)) {
        if (!ethers.utils.isAddress(receiverAddress)) {
          errorSwal("Validation Failed", `Invalid receiver wallet address`);
          return;
        }
      } else {
        errorSwal(
          "Unsupported Chain",
          `Chain ID ${tokenInfo?.chainId} is not supported`
        );
        return;
      }

      // Prepare the request payload
      const url = `${process.env.REACT_APP_API_URL}/merchant-app-tx/merchant-withdraw`;
      const data = {
        appsId: appId,
        tokenId: tokenId,
        amount: withdrawAmount,
        withdrawalAddress: receiverAddress,
        isWithTax: true,
        note: "Withdrawal from merchant panel",
        swapTokenAddress: isSwapPairFound ? tokenB : null,
      };

      console.log("URL: ", url, data);

      // Confirm withdrawal action
      Swal.fire({
        title: "Do you want to withdraw funds?",
        text: "You won't be able to revert this transaction!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Withdraw",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            setIsLoading(true);

            // Execute withdrawal request
            const response = await axios.post(url, data, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
              },
            });

            console.log("Response: ", response);

            // Handle successful transaction
            if (response?.data?.merchantReceipt?.status) {
              Swal.fire({
                title: "Transaction Successful!",
                text: "Transaction initiated successfully. Please wait for network confirmation.",
                icon: "success",
              });
            } else {
              errorSwal(
                "Transaction Failed",
                "Failed to initiate transaction."
              );
            }
          } catch (err) {
            console.error("Error during withdrawal: ", err);
            errorSwal(
              "Transaction Failed",
              err.response?.data?.message || "An unknown error occurred."
            );
          } finally {
            setIsLoading(false);
          }
        }
      });
    } catch (error) {
      console.error("Error in transaction submission: ", error);
      errorSwal(
        "Transaction Failed",
        error.response?.data?.message || "An unknown error occurred."
      );
    } finally {
      setIsLoading(false);
    }
  };

  console.log("appInfo", appInfo);

  const convertSwapAmount = async (amountIn, tokenB) => {
    if (tokenB === null || tokenB === "") {
      setIsSwapPairFound(false);
      setSwapConvertedAmount("");
      return;
    }

    setIsLoading(true);
    try {
      const amountsInWei = await ethers.utils.parseUnits(
        amountIn,
        tokenInfo?.decimal
      );
      console.log("Amount in wei : ", Number(amountsInWei));

      const swapPair = await isSwapPair(
        tokenInfo?.chainId,
        tokenInfo?.address,
        tokenB,
        Number(amountsInWei),
        tokenInfo?.decimal
      );

      setIsSwapPairFound(swapPair.result);

      console.log("AmountOut is : ", swapPair.amountOut);

      setSwapConvertedAmount(swapPair.amountOut);
    } catch (error) {
      console.log("Error in convertSwapAmount : ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputAmountInChange = (event) => {
    const amountIn = event.target.value;

    if (parseFloat(amountIn) <= parseFloat(merchantBalance)) {
      setWithdrawAmount(amountIn);

      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      if (amountIn > 0) {
        timeoutId = setTimeout(() => {
          convertSwapAmount(amountIn, tokenB);
        }, 1000);
      }
    } else {
      setWithdrawAmount("");
      setSwapConvertedAmount("");
    }
  };

  const transaction = useSelector((state) => state?.pdfData);

  console.log("transaction", transaction);

  return (
    <>
      <DashboardHeader />

      {isLoading && <Loader />}

      <div className="site-bg">
        <div className="dashborad-content-bg">
          <aside className="sidebar">
            <Sidebar />
          </aside>
          <div className="dashborad-warper">
            <div className="transactions-list">
              <div className="dashborad-head">
                <div className="breadcrumb-menu">
                  <ul>
                    <li className="active">
                      <Link to="/apps">Apps &rarr; </Link>
                    </li>
                    <li className="active">
                      <Link to={`/apps-detail/${appId}`}>{appInfo?.name}</Link>
                    </li>
                    <li>
                      <a>&rarr; Send/Withdraw</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="white-bg my-apps-content padd20">
                <div className="send-withdraw-list">
                  <ul>
                    <li>
                      <div className="send-withdraw-left">
                        Transaction Balance
                      </div>
                      <div className="send-withdraw-right">
                        {Number(merchantEvmNativeBalance).toFixed(8)}{" "}
                        {tokenInfo?.code.split(".")[1] || tokenInfo?.code}
                      </div>
                    </li>

                    <li>
                      <div className="send-withdraw-left">
                        Minimum Withdrawal
                      </div>
                      <div className="send-withdraw-right">
                        {String(minimumWithdraw).slice(0, 11)}{" "}
                        {tokenInfo?.symbol}
                      </div>
                    </li>

                    <li>
                      <div className="send-withdraw-left">Current Balance</div>
                      <div className="send-withdraw-right">
                        {Number(merchantBalance).toFixed(8)} {tokenInfo?.symbol}
                      </div>
                    </li>
                    <li>
                      <div className="send-withdraw-left">
                        Send / Withdrawal Amount
                      </div>
                      <div className="send-withdraw-right">
                        <div className="send-withdraw-amount">
                          <div className="withdraw-amount-fild">
                            <input
                              type="number"
                              name="withdraw-amount"
                              value={withdrawAmount}
                              className="form-control"
                              placeholder="Enter Amount"
                              onChange={handleInputAmountInChange}
                            />
                          </div>
                          <div
                            className="form-control"
                            style={{
                              textAlign: "center",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "max",
                            }}
                          >
                            {tokenInfo?.symbol}
                          </div>
                          {EVM_CHAIN_ID_LIST.includes(tokenInfo?.chainId) && (
                            <>
                              <div
                                className="usd-btc-box"
                                style={{
                                  height: "max-content",
                                }}
                              >
                                <i
                                  className="ti ti-arrows-exchange"
                                  style={{ fontSize: "24px" }}
                                ></i>
                              </div>

                              {isSwapPairFound && (
                                <div className="withdraw-amount-fild">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Amount"
                                    value={swapConvertedAmount}
                                    disabled={true}
                                  />
                                </div>
                              )}

                              <div className="withdraw-amount-fild">
                                <select
                                  className="form-control"
                                  onChange={async (event) => {
                                    let _tokenB = event.target.value;
                                    console.log(
                                      "Dropdown token is : ",
                                      _tokenB
                                    );
                                    setTokenB(_tokenB);

                                    // If both are the same token, no need to convert swap amount
                                    if (
                                      _tokenB.toLowerCase() ===
                                      tokenInfo?.address.toLowerCase()
                                    ) {
                                      setIsSwapPairFound(false);
                                      return;
                                    } else {
                                      await convertSwapAmount(
                                        withdrawAmount,
                                        _tokenB
                                      );
                                    }
                                  }}
                                >
                                  {tokensList &&
                                    tokensList.map((token, key) => (
                                      <option key={key} value={token.value}>
                                        {token.name}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="send-withdraw-left">Transaction Fee</div>
                      {tokenInfo?.code === "TRX" ? (
                        <div className="send-withdraw-right">
                          0
                          <span style={{ marginLeft: "4px" }}>
                            {tokenInfo?.code.split(".")[1] || tokenInfo?.code}
                          </span>
                        </div>
                      ) : tokenInfo?.code === "USDT.TRX" ? (
                        <div className="send-withdraw-right">
                          <span style={{ marginLeft: "4px" }}>12</span>
                          <span style={{ marginLeft: "4px" }}>
                            {tokenInfo?.code.split(".")[1] || tokenInfo?.code}
                          </span>
                        </div>
                      ) : miniLoader ? (
                        <span style={{ marginLeft: "4px" }}>
                          <ThreeDotLoader />
                        </span>
                      ) : (
                        <div className="send-withdraw-right">
                          {receiverAddress === "" || withdrawAmount === ""
                            ? 0
                            : transactionFee}
                          <span style={{ marginLeft: "4px" }}>
                            {tokenInfo?.code.split(".")[1] || tokenInfo?.code}
                          </span>
                        </div>
                      )}
                    </li>

                    <li>
                      <div className="send-withdraw-left">Total Withdrawal</div>
                      <div
                        className="send-withdraw-right"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        {withdrawAmount || 0} {tokenInfo?.symbol} +
                        {tokenInfo?.code === "TRX" ? (
                          <div className="send-withdraw-right">
                            <span style={{ marginLeft: "4px" }}>0</span>
                            <span style={{ marginLeft: "4px" }}>
                              {tokenInfo?.code.split(".")[1] || tokenInfo?.code}
                            </span>
                          </div>
                        ) : tokenInfo?.code === "USDT.TRX" ? (
                          <div className="send-withdraw-right">
                            <span style={{ marginLeft: "4px" }}>12</span>
                            <span style={{ marginLeft: "4px" }}>
                              {tokenInfo?.code.split(".")[1] || tokenInfo?.code}
                            </span>
                          </div>
                        ) : miniLoader ? (
                          <span style={{ marginLeft: "4px" }}>
                            <ThreeDotLoader />
                          </span>
                        ) : (
                          <div
                            className="send-withdraw-right"
                            style={{ marginLeft: "4px" }}
                          >
                            {receiverAddress === "" || withdrawAmount === ""
                              ? 0
                              : transactionFee}
                            <span style={{ marginLeft: "4px" }}>
                              {tokenInfo?.code.split(".")[1] || tokenInfo?.code}
                            </span>
                          </div>
                        )}
                      </div>
                    </li>

                    <li>
                      <div className="send-withdraw-left">Send To</div>
                      <div className="send-withdraw-right">
                        <div className="address-note-box">
                          <input
                            type="text"
                            value={receiverAddress}
                            className="form-control"
                            placeholder="Enter Address"
                            onChange={(event) => {
                              setReceiverAddress(event.target.value);
                            }}
                            required
                          />
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="send-withdraw-left">Optional Note</div>
                      <div className="send-withdraw-right">
                        <div className="address-note-box">
                          <input
                            type="text"
                            value={note}
                            className="form-control"
                            placeholder="Enter Note"
                            onChange={(event) => {
                              setNote(event.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="request-withdrawal-btn">
                  <button className="btn" onClick={submitTransaction}>
                    Request Withdrawal/Send
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SendWithdraw;
