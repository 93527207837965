import React, { useEffect, useState } from "react";
import DashboardHeader from "../../components/auth/DashboardHeader";
import Sidebar from "../../components/auth/Sidebar";
import { Link, useLocation, useNavigate } from "react-router-dom";

const ManageCryptoCurriencies = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = location.state || {}; // Safely extract 'data' from state

  useEffect(() => {
    if (!data) {
      navigate("/dashboard");
    }
  }, [data]); // Only re-run effect if 'data' changes

  return (
    <>
      <DashboardHeader />

      <div className="site-bg">
        <div className="dashborad-content-bg">
          <aside className="sidebar">
            <Sidebar />
          </aside>
          <div className="dashborad-warper">
            <div className="transactions-list">
              <div className="dashborad-head">
                <div className="breadcrumb-menu">
                  <ul>
                    <li className="active">
                      <Link to="/dashboard">Dashboard</Link>
                    </li>
                    <li>
                      <a>&rarr; Manage Cryptocurrencies</a>
                    </li>
                  </ul>
                </div>
                {/* <div className="view-balances-btn">
                  <a className="btn">View All Balances</a>
                </div> */}
              </div>
              <div className="white-bg my-apps-content padd20">
                <div className="table-box">
                  <table className="table">
                    <tbody>
                      <tr>
                        <th>Coin</th>
                        <th />
                        <th>Balance</th>
                        <th>USD</th>
                      </tr>
                      {data?.data &&
                        data?.data.map((item, key) => (
                          <tr key={key}>
                            <td>{item.name}</td>
                            <td />
                            <td>
                              {item.balance.toFixed(8) || 0}{" "}
                              {item?.symbol.toUpperCase()}
                            </td>

                            <td>
                              {item.currencyConversion.toFixed(8) || "0"} USD
                            </td>
                          </tr>
                        ))}

                      <tr className="entire-wallet-box">
                        <td>Est. Value of Entire Wallets</td>
                        <td />
                        <td />

                        <td>{data?.usdTotal.toFixed(8)} USD</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageCryptoCurriencies;
