import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import axios from "axios";

const DashboardChart = () => {
  const [graphData, setGraphData] = useState(null);

  console.log("graphData : ", graphData);

  async function fetchData() {
    try {
      const url = `${process.env.REACT_APP_API_URL}/payment-link/merchant-payment-links-fund`;

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.status === 200) {
        setGraphData(response?.data?.data); // Ensure you are setting the correct data
      }

      console.log("response : ", response);
    } catch (error) {
      console.log("Error : ", error);
    }
  }

  const chartRef = useRef(null);

  const destroyChart = () => {
    if (chartRef.current) {
      chartRef.current.destroy();
      chartRef.current = null;
    }
  };

  const createChart = () => {
    if (!chartRef.current && graphData) {
      const canvas = document.getElementById("lineChart");
      const ctx = canvas.getContext("2d");

      // Create gradient
      const gradient = ctx.createLinearGradient(0, 0, 0, canvas.height);
      gradient.addColorStop(0, "#e9c02f"); // Start color
      gradient.addColorStop(1, "#f8f6e5"); // End color

      // Process data from the API response
      const months = graphData.map((item) => item.month);
      const totalPrices = graphData.map((item) => item.totalPrice);

      const data = {
        labels: months, // Set the months as the X-axis labels
        datasets: [
          {
            label: "USDT",
            data: totalPrices, // Set the totalPrices as the data for the chart
            borderColor: "#f1d777", // Line color
            backgroundColor: gradient, // Gradient for the area
            tension: 0.5, // Smooth curves
            fill: true, // Enable the area fill
            borderWidth: 2.5, // Thicker line width
          },
        ],
      };

      const config = {
        type: "line", // Use a line chart with a filled area
        data: data,
        options: {
          responsive: true,
          maintainAspectRatio: false, // Allow custom height/width
          plugins: {
            legend: {
              position: "top",
            },
            title: {
              display: true,
              text: "Payment Links (USDT)",
            },
          },
          scales: {
            x: {
              title: {
                display: true,
                text: "Months",
              },
            },
            y: {
              title: {
                display: true,
                text: "Amount (USDT)",
              },
              beginAtZero: true,
            },
          },
        },
      };

      chartRef.current = new Chart(ctx, config);
    }
  };

  useEffect(() => {
    fetchData();
    return () => destroyChart(); // Clean up chart when the component unmounts
  }, []);

  useEffect(() => {
    if (graphData) {
      createChart(); // Create chart only when the data is available
    }
  }, [graphData]);

  return (
    <div
      className="chart-container"
      style={{
        width: "1100px", // Increased width
        height: "400px", // Fixed height
        margin: "0 auto", // Center the chart
      }}
    >
      <canvas id="lineChart" width="1100" height="400"></canvas>
    </div>
  );
};

export default DashboardChart;
