import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Link } from "react-router-dom";
import axios from "axios";
import SwaggerUI from "swagger-ui";
import "swagger-ui/dist/swagger-ui.css";
// import './Apis.css'; // Import custom CSS

const Apis = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [activeSection, setActiveSection] = useState("section1");

  const fetchPages = async () => {
    try {
      const response = await axios
        .get(`${process.env.REACT_APP_API_URL}/other-pages/slug`, {
          params: { slug: "apis" },
        })
        .then((res) => {
          console.log("res", res);
          setTitle(res?.data?.page?.title);
          setDescription(res?.data?.page?.description);
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.error("Error fetching pages:", error);
    }
  };

  useEffect(() => {
    SwaggerUI({
      dom_id: "#swagger-ui",
      url: "new.yaml",
    });
    fetchPages();

    // Add scroll event listener with 250px offset
    const handleScroll = () => {
      const sections = document.querySelectorAll(".section");
      const scrollPosition = window.scrollY + 250; // Changed to 250px offset

      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;

        if (
          scrollPosition >= sectionTop &&
          scrollPosition < sectionTop + sectionHeight
        ) {
          setActiveSection(section.id);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    // Call handleScroll initially to set the active section on page load
    handleScroll();

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Handle click on navigation items
  const handleNavClick = (sectionId) => {
    setActiveSection(sectionId);
  };

  return (
    <>
      <Header />
      <div className="site-bg">
        <section className="inner-page-head">
          <div className="container">
            <h1>{title}</h1>
            <ul className="breadcrumb">
              <li className="home">
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/apis">&rarr; APIs</Link>
              </li>
            </ul>
          </div>
        </section>
        <section className="api-section py-5">
          <div className="container">
            <div className="row">
              <nav className="col-sm-3" id="sidebar">
                <div
                  id="list-example"
                  className="list-group " /* data-spy="affix" data-offset-top="224" data-offset-bottom="320" */
                >
                  <a
                    href="#section1"
                    className={`list-group-item list-group-item-action ${
                      activeSection === "section1" ? "active" : ""
                    }`}
                    onClick={() => handleNavClick("section1")}
                  >
                    Introduction
                  </a>
                  <a
                    href="#section2"
                    className={`list-group-item list-group-item-action ${
                      activeSection === "section2" ? "active" : ""
                    }`}
                    onClick={() => handleNavClick("section2")}
                  >
                    API Keys
                  </a>
                  <a
                    href="#section3"
                    className={`list-group-item list-group-item-action ${
                      activeSection === "section3" ? "active" : ""
                    }`}
                    onClick={() => handleNavClick("section3")}
                  >
                    Playground
                  </a>
                  <a
                    href="#section4"
                    className={`list-group-item list-group-item-action ${
                      activeSection === "section4" ? "active" : ""
                    }`}
                    onClick={() => handleNavClick("section4")}
                  >
                    Subscription
                  </a>
                </div>
              </nav>

              <div className="col-sm-9">
                <div
                  data-bs-spy="scroll"
                  data-bs-target="#list-example"
                  data-bs-smooth-scroll="true"
                  class="scrollspy-example d-flex flex-column gap-4"
                  tabindex="0"
                >
                  <div id="section1" className="section">
                    <h1>Introduction</h1>
                    <h4>
                      Welcome to Coinpera, your all-in-one solution for seamless
                      cryptocurrency payments.
                    </h4>
                    <h5>
                      Coinpera empowers your platform to effortlessly integrate
                      and accept payments in a variety of cryptocurrencies,
                      whether you prefer to support a single crypto or multiple
                      options. Our comprehensive gateway is designed to provide
                      a streamlined, efficient, and secure payment experience,
                      enabling your business to thrive in the digital economy.
                    </h5>
                  </div>

                  <div id="section2" className="section">
                    <h1>API Keys</h1>
                    <p>Get your Api keys here.</p>

                    <Link to="/dashboard">
                      <span>API Keys</span>
                    </Link>
                  </div>

                  <div id="section3" className="section">
                    <h1>Playground</h1>
                    <div id="swagger-ui"></div>
                  </div>

                  <div id="section4" className="section">
                    <h1>Subscription</h1>
                    <p>Choose the plan that best suits your needs.</p>
                    <table className="subscription-table table">
                      <thead>
                        <tr>
                          <th>Plan</th>
                          <th>Price</th>
                          <th>Features</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Basic</td>
                          <td>$10/month</td>
                          <td>Access to basic API endpoints</td>
                        </tr>
                        <tr>
                          <td>Pro</td>
                          <td>$30/month</td>
                          <td>
                            Access to all API endpoints, higher rate limits
                          </td>
                        </tr>
                        <tr>
                          <td>Enterprise</td>
                          <td>Contact us</td>
                          <td>Custom solutions, dedicated support</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Apis;
